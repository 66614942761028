import {BREAKPOINT} from 'aac-components/utils/styles';
import styled from 'styled-components';

export const CalloutStyles = styled.div`
    .c-callout__button {
        text-decoration: none !important;
    }
`;
export const ButtonStyles = styled.div`
    button,
    a {
        font-weight: normal;
    }
    a:hover {
        color: #fff !important;
    }
`;

export const ExtraMargin = styled.div`
    .financing-inquiry-form,
    .self-assessment,
    .cta__button--container,
    .persona-assessment {
        margin: 0 16px;
    }
`;

export const ContactFormStyles = styled.div`
    .container {
        box-shadow: var(--box-shadow);
        padding: 0;
        border-radius: 8px;
        margin: 0 0 40px 0;
        border: 1px solid var(--gray-200);
        overflow: hidden;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .container {
            margin: 0 15px 80px 15px;
            padding: 0;
        }
    }
    .contact-vob__container {
        margin: 40px 15px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .contact-vob__container {
            margin: 80px 15px;
        }
    }
    .form__content {
        padding: 16px 16px 0 16px;
        border-radius: 8px;
    }
`;

export const LinkBankStyles = styled.div`
    .link-bank {
        margin: 40px 0;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .link-bank {
            margin: 80px 0;
            padding: 40px 16px !important;
        }
    }
    .link-bank__container {
        margin: 0 auto;
        max-width: fit-content;
    }
    .link-bank ul {
        display: grid;
        grid-template-columns: 1fr;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .link-bank ul {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 8px 40px;
            max-width: fit-content;
            margin: 0 auto;
        }
    }
    .link-bank ul > li > ul {
        display: block;
    }
`;
